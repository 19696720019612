import { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import Actions from 'src/state/root-actions';

const Logout = ({ dispatch }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(
            Actions.auth.logout.trigger({
                onFailure: (message) => {
                    //TODO: what is the appropriate site level messaging for errors?
                    console.log(message);
                },
                onSuccess: () => {
                    navigate('/');
                },
            })
        );
    }, []);

    return null;
};

export default connect()(Logout);
